import { message } from "antd";
import axios from "axios";
import qs from "qs";
export const baseURL = '/meeting'
export const Authorization = 'Basic ZG1pbmRfd2ViOmRtaW5kX3dlYl9zZWNyZXQ='
// create an axios instance
const request = axios.create({
  baseURL,
  timeout: 180000,
  headers: {
    'Content-Type': 'application/json',
    'x-requested-with': 'xmlhttprequest',
    Authorization
  },
});

// request interceptor
request.interceptors.request.use(
  (config) => {
    let userInfo = JSON.parse(localStorage.getItem("USER_INFO") || '{}');
    const { tenant, token } = userInfo;
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data, {
        arrayFormat: 'indices',
        allowDots: true,
      });
    }
    if (tenant) {
      config.headers.tenant = tenant.tenantCode;
    }
    if (token) {
      config.headers.token = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone interceptor
request.interceptors.response.use(
  (response) => {
    /**
     * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
     * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
     */
    const res = response.data;
    const { data, code, msg } = res;

    if (code === 0) {
      return data;
    }
    if (code === undefined) {
      return response
    }

    msg && message.error(msg);
    return Promise.reject("error");
  },
  (error) => {
    try {
      const { status, data } = error.response;
      const { code, msg, errorMsg } = data;
      const errMsg = errorMsg || msg || data;

      if (errMsg && typeof errMsg === "string") {
        message.error(errMsg, undefined, () => {
          if (code == '403') {
            window.location.hash = '/login'
          }
        });
      }
    } catch (error) { }
    return Promise.reject(error);
  }
);

export default request;
